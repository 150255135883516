<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-card>

        <v-card-text>
          <l-map
            :zoom="zoom"
            :center="center"
            style="height: 500px; width: 100%"
            @click="addMarker"
          >
            <l-tile-layer :url="url" />
            <l-marker :key="index" v-for="(marker, index) in latlngs" :lat-lng="marker"
                      @click="removeMarker(index)"></l-marker>
            <l-marker :icon="branchIcon" v-for="branch in branches" :lat-lng="branch.location" :key="branch.name">
              <l-popup>{{ branch.name }}</l-popup>
            </l-marker>
            <l-polygon
              :lat-lngs="latlngs"
              :color="color"
              :fillColor="color"
            />
          </l-map>

        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-color-picker
            class="mb-3"
            v-model="color"
            flat
          ></v-color-picker>
          <v-btn
            class="mb-3"
            color="error"
            block
            outlined
            @click="cancel"
          >
            Отмена
          </v-btn>
          <v-btn
            class="mb-3"
            block
            :disabled="hasNotPermission('Permissions.DeliveryAreaUpdate')"
            color="success"
            outlined
            @click="submitData"
          >
            Применить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { LMap, LTileLayer, LMarker, LCircle, LPolygon, LPopup } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { mapActions, mapState } from 'vuex'
import { Icon, icon } from 'leaflet'
import { mapFormHandlers } from '@/helpers/forms'
import { hasPermission } from '@/helpers'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: { LMap, LPolygon, LMarker, LTileLayer, LPopup },
  name: 'DeliveryAreaEdit',
  data: () => ({
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    zoom: 11,
    branchIcon: {},
  }),
  computed: {
    ...mapState('deliveryArea', {
      latlngs: 'latlngs',
      branches: 'branches',
    }),
    ...mapFormHandlers('deliveryArea', [
      'color',
      'center',
    ]),
  },
  async created() {
    document.title = 'Зона доставки'
    this.branchIcon = new icon({
      iconUrl: require('@/assets/images/logos/pizza-empire.png'),
      iconSize: [25, 25],
      iconAnchor: [16, 37],
    })
    const { cityId } = this.$route.params
    this.fetchDeliveryArea(cityId)
  },
  methods: {
    ...mapActions('deliveryArea', { fetchDeliveryArea: 'fetchForCity', updateDeliveryArea: 'update' }),
    addMarker(event) {
      this.latlngs.push(event.latlng)
    },
    hasNotPermission(val){
      return !hasPermission(val);
    },
    removeMarker(index) {
      this.latlngs.splice(index, 1)
    },
    cancel() {
      this.$router.back()
    },
    async submitData() {
      const { cityId } = this.$route.params
      await this.updateDeliveryArea({
        cityId: cityId,
        polygon: this.latlngs,
        color: this.color,
      })
      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
</style>
